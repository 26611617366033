import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/layout"
import { Container } from "../components/Container2"
import styled from "styled-components"
import { buttonMixin } from "../components/Button"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"

const Input = styled.input`
  display: inline-block;
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8);
  padding: 15px 60px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9em;

  outline: none;

  &:focus {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }

  margin-top: 6px;
`

const Textarea = styled.textarea`
  border-width: 2px;
  border-radius: 50px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8);
  padding: 15px 60px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9em;

  outline: none;

  &:focus {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }

  margin-top: 6px;

  resize: vertical;

  height: 200px;
`

const ContactPageOuterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em;
  }
`

const ContactPageInnerWrapper = styled.div`
  width: 80%;
  max-width: 840px;
`

const Field = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 15px;

  width: ${({ width }: { width: number }) => width ?? 100}%;

  & label {
    font-weight: 700;
    padding-left: 20px;
    font-size: 0.85em;
  }

  @media (max-width: 950px) {
    width: 100%;
  }
`

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-end;
  }

  & #contact-recaptcha {
    margin-right: 10px;
  }
`

const SubmitButton = styled.button`
  ${buttonMixin};
  color: white;
  background-color: rgba(0, 0, 0, 0.8);

  &:hover {
    background-color: white;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
  }
`

const FieldError = styled.div`
  padding-left: 20px;
  color: #ff0052;
  font-size: 0.85em;
`

type FormInputs = {
  name: string
  phone: string
  email: string
  message: string
}

const notNullOrEmpty = (s: string): boolean => s != null && s != ""

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = ({ data }: { data: any }) => {
  const { register, handleSubmit, errors, getValues } = useForm<FormInputs>()
  const [recaptchaValue, setRecaptchaValue] = useState<any>()

  const submitForm = (data, e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...data,
        "g-recaptcha-response": recaptchaValue,
      }),
    })
      .then(() =>
        navigate("/takk-for-henvendelse", {
          state: { name: data?.name },
        })
      )
      .catch(console.error)

    e.preventDefault()
  }

  return (
    <Layout>
      <SEO title="Kontakt" />
      <div style={{ width: "50px", height: "12vw", maxHeight: "130px" }} />
      <ContactPageOuterWrapper>
        <ContactPageInnerWrapper>
          <Container blocks={data.sanityContactPage._rawContent} />
          {/* <form
            data-netlify="true"
            netlify-honeypot="bot-field"
            name="contact"
            hidden
          >
            <input type="hidden" name="name" />
            <input type="hidden" name="phone" />
            <input type="hidden" name="email" />
            <input type="hidden" name="message" />
            <div data-netlify-recaptcha="true" />
          </form> */}
          <form
            method="post"
            data-netlify="true"
            data-netlify-recaptcha="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(submitForm)}
            name="contact"
          >
            <input type="hidden" name="bot-field" />
            <Field width={100}>
              <label>Navn</label>
              <Input
                name="name"
                type="text"
                placeholder="Ditt navn"
                ref={register({ required: true })}
              />
              <FieldError>
                {errors.name && "Fortell oss hva du heter!"}
              </FieldError>
            </Field>
            <Field width={50}>
              <label>Tlf</label>
              <Input
                name="phone"
                type="tel"
                placeholder="Ditt telefonnummer"
                ref={register({
                  validate: () =>
                    notNullOrEmpty(getValues("phone")) ||
                    notNullOrEmpty(getValues("email")),
                })}
              />
            </Field>
            <Field width={50}>
              <label>Epost</label>
              <Input
                name="email"
                type="email"
                placeholder="Din epostadresse"
                ref={register}
              />
            </Field>

            <FieldError>
              {(errors.phone || errors.email) &&
                "Vi trenger enten tlf eller epost for å kunne kontakte deg."}
            </FieldError>
            <Field width={100}>
              <label>Melding</label>
              <Textarea
                name="message"
                placeholder="Din melding"
                ref={register({ required: true })}
              />
              <FieldError>
                {errors.message && (
                  <span>
                    Fortell oss <i>noe</i> da, vel!
                  </span>
                )}
              </FieldError>
            </Field>
            <SubmitWrapper>
              <ReCAPTCHA
                id="contact-recaptcha"
                sitekey="6LeAV1EaAAAAAKgRes_Nuou_xlrReykHOCgqA7BZ"
                onChange={value => setRecaptchaValue(value)}
                size="compact"
              />
              <SubmitButton type="submit">Send melding</SubmitButton>
            </SubmitWrapper>
          </form>
        </ContactPageInnerWrapper>
      </ContactPageOuterWrapper>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  {
    sanityContactPage {
      _rawContent
    }
  }
`
